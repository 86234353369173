import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import * as Icon from 'feather-icons-react';

class Sidebar extends Component {

  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/general', state: 'generalMenuOpen' },
      { path: '/users', state: 'usersMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/advanced-ui', state: 'advancedUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/maps', state: 'mapsMenuOpen' },
      { path: '/editors', state: 'editorsMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
      { path: '/general-pages', state: 'generalPagesMenuOpen' },
      { path: '/ecommerce', state: 'ecommercePagesMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas sidebar-boxshadow" id="sidebar">
        <ul className="nav">
          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <Icon.Home className="menu-icon" />
              <span className="menu-title">Home</span>
            </Link>
          </li>
          <li className={this.isPathActive('/actions') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/actions">
              {/* <i className="mdi mdi mdi-clock-alert menu-icon"></i> */}
              <Icon.Target className="menu-icon" />
              <span className="menu-title">Activity Log</span>
            </Link>
          </li>



          <li className={this.isPathActive('/apps') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.appsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('appsMenuOpen')} data-toggle="collapse">
              <Icon.Layers className="menu-icon" />
              <span className="menu-title">Application Configuration</span>
              <i className="menu-arrow"></i>
            </div>

            <Collapse in={this.state.appsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/apps/observation') ? 'nav-link active' : 'nav-link'} to="/apps/observation">Observation Reporting</Link></li>
                {/* <li className="nav-item"> <Link className={this.isPathActive('/apps/incident-config') ? 'nav-link active' : 'nav-link'} to="/apps/incident-config">Incident Reporting</Link></li> */}
                {/* <li className="nav-item"> <Link className={this.isPathActive('/apps/eptw') ? 'nav-link active' : 'nav-link'} to="/apps/eptw">ePermit to Work</Link></li> */}
                {/* <li className="nav-item"> <Link className={this.isPathActive('/apps/inspection') ? 'nav-link active' : 'nav-link'} to="/apps/inspection">Inspection and Audit</Link></li> */}
                {/* <li className="nav-item"> <Link className={this.isPathActive('/apps/document') ? 'nav-link active' : 'nav-link'} to="/apps/document">Document Management</Link></li> */}
                {/* <li className="nav-item"> <Link className={this.isPathActive('/apps/asset') ? 'nav-link active' : 'nav-link'} to="/apps/asset">Asset Management</Link></li> */}
                {/* <li className="nav-item"> <Link className={this.isPathActive('/apps/knowledge') ? 'nav-link active' : 'nav-link'} to="/apps/knowledge">Competency Management</Link></li> */}
                {/* <li className="nav-item"> <Link className={this.isPathActive('/apps/plant') ? 'nav-link active' : 'nav-link'} to="/apps/plant">Plant and Equipment</Link></li> */}
       
                <li className="nav-item"> <Link className={this.isPathActive('/apps/risk') ? 'nav-link active' : 'nav-link'} to="/apps/risk">Risk Assessment</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/apps/report') ? 'nav-link active' : 'nav-link'} to="/apps/report">Reports</Link></li>
                <li className={this.isPathActive('/checklist') ? 'nav-item active' : 'nav-item'}>
                  <Link className={this.isPathActive('/checklist') ? 'nav-link active' : 'nav-link'} to="/checklist">
                  
                    Checklist Library
                  </Link>
                </li>

              </ul>
            </Collapse>
          </li>
          <li className={this.isPathActive('/general') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.generalMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('generalMenuOpen')} data-toggle="collapse">
              <Icon.Tool className="menu-icon" />
              <span className="menu-title">General Configuration</span>
              <i className="menu-arrow"></i>
            </div>

            <Collapse in={this.state.generalMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/general/contractor') ? 'nav-link active' : 'nav-link'} to="/general/contractor">Contractor</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/general/location') ? 'nav-link active' : 'nav-link'} to="/general/location">Location</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/general/workactivity') ? 'nav-link active' : 'nav-link'} to="/general/workactivity">Work Activities</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/general/department') ? 'nav-link active' : 'nav-link'} to="/general/department">Department</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/general/designation') ? 'nav-link active' : 'nav-link'} to="/general/designation">Designation</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/general/workinggroup') ? 'nav-link active' : 'nav-link'} to="/general/workinggroup">Working Group</Link></li>
                {/* <li className="nav-item"> <Link className={this.isPathActive('/general/document') ? 'nav-link active' : 'nav-link'} to="/general/document">Document</Link></li> */}
                {/* <li className="nav-item"> <Link className={this.isPathActive('/general/hazardlist') ? 'nav-link active' : 'nav-link'} to="/general/hazardlist">Hazard Config</Link></li> */}

              </ul>
            </Collapse>
          </li>
          <li className={this.isPathActive('/users') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.usersMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('usersMenuOpen')} data-toggle="collapse">
              <Icon.User className="menu-icon" />
              <span className="menu-title">User Management</span>
              <i className="menu-arrow"></i>
            </div>

            <Collapse in={this.state.usersMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/users/user') ? 'nav-link active' : 'nav-link'} to="/users/user">Internal Azure Users</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/users/app-user') ? 'nav-link active' : 'nav-link'} to="/users/app-user">External Users</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/users/general-user') ? 'nav-link active' : 'nav-link'} to="/users/general-user">General Users</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/users/master-user') ? 'nav-link active' : 'nav-link'} to="/users/master-user">Users Master Data</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/users/role-user') ? 'nav-link active' : 'nav-link'} to="/users/role-user">Assignment Report</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/users/project-user') ? 'nav-link active' : 'nav-link'} to="/users/project-user">Project Assignment</Link></li>

              </ul>
            </Collapse>
          </li>

          {/* <li className={this.isPathActive('/ehs') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/ehs">
              <i className="mdi mdi-eye menu-icon"></i>
              <span className="menu-title">EHS Observation</span>
            </Link>
          </li>

          <li className={this.isPathActive('/eptw') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/eptw">
              <i className="mdi mdi-note-text-outline menu-icon"></i>
              <span className="menu-title">ePermit to Work</span>
            </Link>
          </li>

          <li className={this.isPathActive('/incident') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/incident">
              <i className="mdi mdi-alert-circle-outline menu-icon"></i>
              <span className="menu-title">IR Reporting</span>
            </Link>
          </li>
          <li className={this.isPathActive('/groups') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/groups">
              <i className="mdi mdi-account-multiple menu-icon"></i>
              <span className="menu-title">Groups</span>
            </Link>
          </li>
          <li className={this.isPathActive('/groupassign') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/groupassign">
              <i className="mdi mdi-floor-plan menu-icon"></i>
              <span className="menu-title">Groups Assessment</span>
            </Link>
          </li>

          <li className={this.isPathActive('/inspection') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/inspection">
              <i className="mdi mdi-clipboard-text-search-outline menu-icon"></i>
              <span className="menu-title">Inspection and Audit</span>
            </Link>
          </li>

          <li className={this.isPathActive('/risk-assessment') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/risk-assessment">
              <i className="mdi mdi-flash-alert menu-icon"></i>
              <span className="menu-title">Risk Assessment</span>
            </Link>
          </li>



          <li className={this.isPathActive('/plant') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/plant">
              <i className="mdi mdi-factory menu-icon"></i>
              <span className="menu-title">Plant and Equipment </span>
            </Link>
          </li>

          <li className={this.isPathActive('/documents') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/documents">
              <i className="mdi mdi-file-document menu-icon"></i>
              <span className="menu-title">Documents Library </span>
            </Link>
          </li> */}

          {/* <li className={this.isPathActive('/settings') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.appsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('appsMenuOpen')} data-toggle="collapse">
              <i className="mdi mdi-cog menu-icon"></i>
              <span className="menu-title">Settings</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.appsMenuOpen}>
              <ul className="nav flex-column sub-menu">

              

                <li className={this.isPathActive('/user') ? 'nav-item active' : 'nav-item'}>
                  <Link className="nav-link" to="/user">
                    <i className="mdi mdi-badge-account menu-icon"></i>
                    <span className="menu-title">Internal Azure Users</span>
                  </Link>
                </li>

                <li className={this.isPathActive('/app-user') ? 'nav-item active' : 'nav-item'}>
                  <Link className="nav-link" to="/app-user">
                    <i className="mdi mdi-home-account menu-icon"></i>
                    <span className="menu-title">External Users</span>
                  </Link>
                </li>

                <li className={this.isPathActive('/general-user') ? 'nav-item active' : 'nav-item'}>
                  <Link className="nav-link" to="/general-user">
                    <i className="mdi mdi-account-details menu-icon"></i>
                    <span className="menu-title">General Users</span>
                  </Link>
                </li>

                <li className={this.isPathActive('/master-user') ? 'nav-item active' : 'nav-item'}>
                  <Link className="nav-link" to="/master-user">
                    <i className="mdi mdi-account-group menu-icon"></i>
                    <span className="menu-title">Users Master Data</span>
                  </Link>
                </li>

                <li className={this.isPathActive('/role-user') ? 'nav-item active' : 'nav-item'}>
                  <Link className="nav-link" to="/role-user">
                    <i className="mdi mdi-chart-bar menu-icon"></i>
                    <span className="menu-title">Assignment Report</span>
                  </Link>
                </li>

                <li className={this.isPathActive('/project-user') ? 'nav-item active' : 'nav-item'}>
                  <Link className="nav-link" to="/project-user">
                    <i className="mdi mdi-selection-marker menu-icon"></i>
                    <span className="menu-title">Project Assignment</span>
                  </Link>
                </li>

                <li className={this.isPathActive('/logout') ? 'nav-item active' : 'nav-item'}>
                  <Link className="nav-link" to="/logout">
                    <i className="mdi mdi-power menu-icon"></i>
                    <span className="menu-title">Logout</span>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li> */}
          <li className={this.isPathActive('/logout') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/logout">
              <Icon.LogOut className="menu-icon" />
              <span className="menu-title">Logout</span>
            </Link>
          </li>
          {/* <li className={this.isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('basicUiMenuOpen')} data-toggle="collapse">
              <i className="menu-icon mdi mdi-form-dropdown"></i>
              <span className="menu-title">Dropdown Config</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.basicUiMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className={this.isPathActive('/location') ? 'nav-item active' : 'nav-item'}>
                  <Link className="nav-link" to="/location">
                    <i className="mdi mdi-map-marker menu-icon"></i>
                    <span className="menu-title">Locations</span>
                  </Link>
                </li>

                <li className={this.isPathActive('/gms') ? 'nav-item active' : 'nav-item'}>
                  <Link className="nav-link" to="/gms">
                    <i className="mdi mdi-cube-outline menu-icon"></i>
                    <span className="menu-title">GMS</span>
                  </Link>
                </li>
                <li className={this.isPathActive('/activity') ? 'nav-item active' : 'nav-item'}>
                  <Link className="nav-link" to="/activity">
                    <i className="mdi mdi-hammer-screwdriver menu-icon"></i>
                    <span className="menu-title">Work Activities</span>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li> */}
          {/* <li className={ this.isPathActive('/advanced-ui') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.advancedUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('advancedUiMenuOpen') } data-toggle="collapse">
              <i className="menu-icon mdi mdi-arrow-down-drop-circle-outline"></i>
              <span className="menu-title">Advanced UI</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.advancedUiMenuOpen }>
              <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <Link className={ this.isPathActive('/advanced-ui/dragula') ? 'nav-link active' : 'nav-link' } to="/advanced-ui/dragula">Dragula</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/advanced-ui/clipboard') ? 'nav-link active' : 'nav-link' } to="/advanced-ui/clipboard">Clipboard</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/advanced-ui/context-menu') ? 'nav-link active' : 'nav-link' } to="/advanced-ui/context-menu">Context menu</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/advanced-ui/sliders') ? 'nav-link active' : 'nav-link' } to="/advanced-ui/sliders">Sliders</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/advanced-ui/carousel') ? 'nav-link active' : 'nav-link' } to="/advanced-ui/carousel">Carousel</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/advanced-ui/loaders') ? 'nav-link active' : 'nav-link' } to="/advanced-ui/loaders">Loaders</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/advanced-ui/tree-view') ? 'nav-link active' : 'nav-link' } to="/advanced-ui/tree-view">Tree View</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/form-elements') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('formElementsMenuOpen') } data-toggle="collapse">
              <i className="menu-icon mdi mdi-card-text-outline"></i>
              <span className="menu-title">Form Elements</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.formElementsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/form-elements/basic-elements') ? 'nav-link active' : 'nav-link' } to="/form-elements/basic-elements">Basic Elements</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/form-elements/advanced-elements') ? 'nav-link active' : 'nav-link' } to="/form-elements/advanced-elements">Advanced Elements</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/form-elements/wizard') ? 'nav-link active' : 'nav-link' } to="/form-elements/wizard">Wizard</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/editors') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.editorsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('editorsMenuOpen') } data-toggle="collapse">
              <i className="menu-icon mdi mdi-code-braces"></i>
              <span className="menu-title">Editors</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.editorsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/editors/text-editors') ? 'nav-link active' : 'nav-link' } to="/editors/text-editors">Text Editor</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/editors/code-editor') ? 'nav-link active' : 'nav-link' } to="/editors/code-editor">Code Editor</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/charts') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.chartsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('chartsMenuOpen') } data-toggle="collapse">
              <i className="menu-icon mdi mdi-chart-line"></i>
              <span className="menu-title">Charts</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.chartsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/charts/chart-js') ? 'nav-link active' : 'nav-link' } to="/charts/chart-js">Chart Js</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/charts/c3-chart') ? 'nav-link active' : 'nav-link' } to="/charts/c3-chart">C3 Charts</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/charts/chartist') ? 'nav-link active' : 'nav-link' } to="/charts/chartist">Chartist</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/charts/google-charts') ? 'nav-link active' : 'nav-link' } to="/charts/google-charts">Google Charts</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/charts/sparkline-charts') ? 'nav-link active' : 'nav-link' } to="/charts/sparkline-charts">Sparkline Charts</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/charts/guage-chart') ? 'nav-link active' : 'nav-link' } to="/charts/guage-chart">Guage Chart</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/tables') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.tablesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('tablesMenuOpen') } data-toggle="collapse">
              <i className="menu-icon mdi mdi-table"></i>
              <span className="menu-title">Tables</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/basic-table') ? 'nav-link active' : 'nav-link' } to="/tables/basic-table">Basic Table</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/react-table') ? 'nav-link active' : 'nav-link' } to="/tables/react-table">React Table</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/sortable-table') ? 'nav-link active' : 'nav-link' } to="/tables/sortable-table">Sortable Table</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/notifications') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/notifications">
              <i className="menu-icon mdi mdi-bell-alert-outline"></i>
              <span className="menu-title">Notifications</span>
            </Link>
          </li>
          <li className={ this.isPathActive('/icons') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('iconsMenuOpen') } data-toggle="collapse">
              <i className="menu-icon mdi mdi-layers-outline"></i>
              <span className="menu-title">Icons</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.iconsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/icons/mdi') ? 'nav-link active' : 'nav-link' } to="/icons/mdi">Material</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/icons/flag-icons') ? 'nav-link active' : 'nav-link' } to="/icons/flag-icons">Flag icons</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/icons/font-awesome') ? 'nav-link active' : 'nav-link' } to="/icons/font-awesome">Font Awesome</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/icons/simple-line') ? 'nav-link active' : 'nav-link' } to="/icons/simple-line">Simple Line Icons</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/icons/themify') ? 'nav-link active' : 'nav-link' } to="/icons/themify">Themify</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/icons/typicons') ? 'nav-link active' : 'nav-link' } to="/icons/typicons">Typicons</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/maps') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.mapsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('mapsMenuOpen') } data-toggle="collapse">
              <i className="menu-icon mdi mdi-google-maps"></i>
              <span className="menu-title">Maps</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.mapsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/maps/vector-map') ? 'nav-link active' : 'nav-link' } to="/maps/vector-map">Vector Maps</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/maps/simple-map') ? 'nav-link active' : 'nav-link' } to="/maps/simple-map">Simple Maps</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/user-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.userPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('userPagesMenuOpen') } data-toggle="collapse">
              <i className="menu-icon mdi mdi-account-circle-outline"></i>
              <span className="menu-title">User Pages</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.userPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/login-1') ? 'nav-link active' : 'nav-link' } to="/user-pages/login-1">Login</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/login-2') ? 'nav-link active' : 'nav-link' } to="/user-pages/login-2">Login 2</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/register-1') ? 'nav-link active' : 'nav-link' } to="/user-pages/register-1">Register</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/register-2') ? 'nav-link active' : 'nav-link' } to="/user-pages/register-2">Register 2</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/lockscreen') ? 'nav-link active' : 'nav-link' } to="/user-pages/lockscreen">Lockscreen</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/error-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.errorPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('errorPagesMenuOpen') } data-toggle="collapse">
              <i className="menu-icon mdi mdi-stop-circle-outline"></i>
              <span className="menu-title">Error Pages</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.errorPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/error-pages/error-404') ? 'nav-link active' : 'nav-link' } to="/error-pages/error-404">404</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/error-pages/error-500') ? 'nav-link active' : 'nav-link' } to="/error-pages/error-500">500</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/general-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.generalPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('generalPagesMenuOpen') } data-toggle="collapse">
              <i className="menu-icon mdi mdi-book-open"></i>
              <span className="menu-title">General Pages</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.generalPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/general-pages/blank-page') ? 'nav-link active' : 'nav-link' } to="/general-pages/blank-page">Blank Page</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/general-pages/profile') ? 'nav-link active' : 'nav-link' } to="/general-pages/profile">Profile</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/general-pages/faq-1') ? 'nav-link active' : 'nav-link' } to="/general-pages/faq-1">FAQ</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/general-pages/faq-2') ? 'nav-link active' : 'nav-link' } to="/general-pages/faq-2">FAQ 2</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/general-pages/news-grid') ? 'nav-link active' : 'nav-link' } to="/general-pages/news-grid">News Grid</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/general-pages/timeline') ? 'nav-link active' : 'nav-link' } to="/general-pages/timeline">Timeline</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/general-pages/search-results') ? 'nav-link active' : 'nav-link' } to="/general-pages/search-results">Search Results</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/general-pages/portfolio') ? 'nav-link active' : 'nav-link' } to="/general-pages/portfolio">Portfolio</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/ecommerce') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.ecommercePagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('ecommercePagesMenuOpen') } data-toggle="collapse">
              <i className="menu-icon mdi mdi-cart-arrow-down"></i>
              <span className="menu-title">E-commerce</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.ecommercePagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/ecommerce/invoice') ? 'nav-link active' : 'nav-link' } to="/ecommerce/invoice">Invoice</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/ecommerce/pricing') ? 'nav-link active' : 'nav-link' } to="/ecommerce/pricing">Pricing</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/ecommerce/product-catalogue') ? 'nav-link active' : 'nav-link' } to="/ecommerce/product-catalogue">Product Catalogue</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/ecommerce/project-list') ? 'nav-link active' : 'nav-link' } to="/ecommerce/project-list">Project List</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="http://bootstrapdash.com/demo/staradmin-react-pro/documentation/documentation.html" rel="noopener noreferrer" target="_blank">
              <i className="menu-icon mdi mdi-file-document"></i>
              <span className="menu-title">Documentation</span>
            </a>
          </li> */}
          <li className="nav-item" style={{ position: 'absolute', bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center', fontSize: 10, textAlign: 'center', background: '#fff' }}>


            <p className="menu-title m-0" ><p style={{ marginBottom: '-10px' }}>POWERED BY </p><img src={require('../../assets/images/acuizen-new-logo.png')} style={{ width: 120 }} alt='test' /></p>

          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);